import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export const ProjectCard = ({ title, description, imgUrl ,link }) => {
  return (
    <>
    {
      link ? (
        <Col size={12} sm={6} md={4}>
          <a href={`${link}`} target="blank" style={{textDecoration:"none"}}>
        <div className="proj-imgbx">
          <img src={imgUrl} />
          <div className="proj-txtx">
            <h4 style={{color:"white" }}>{title}</h4>
            <span style={{color:"white" }}>{description}</span>
          </div>
        </div>
        </a>
      </Col>
      ):(
        <Col size={12} sm={6} md={4} className=" projectcard-col">
        <div className="proj-imgbx">
          <img src={imgUrl} />
          <div className="proj-txtx">
            <h4>{title}</h4>
            <span>{description}</span>
          </div>
        </div>
      </Col>
      )
    }
      </>
  )
}
