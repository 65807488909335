import React from 'react'
import { PopupButton } from "react-calendly";
import '../App.css';
const Calendly = () => {
  return (
    <div className='cal-func'>
      <PopupButton id="popbutton"
        url="https://calendly.com/himanshu1662/30min"
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
      />
</div>
  )
}
export default Calendly;